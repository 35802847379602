import { ConsentFormFields } from './ConsentFormFields';

/**
 * TODO:
 * this method is manually copied to azure function createConsent to validate data!!!
 * move below method to a common npm package
 * @param values 
 * @param knownPartnerTelError error fetched from 3rd party lib(error that could not be found from @function formikValidator)
 * @returns 
 */
export const validateConsentForm = (values: ConsentFormFields, knownPartnerTelError?: string): ConsentFormFields => {
	const errors = {} as ConsentFormFields;
	
	consentCheckboxValidator(values as ConsentFormFields, errors, knownPartnerTelError);
	return errors;
};

const consentCheckboxValidator = (values: ConsentFormFields, errors, knownPartnerTelError) => {
	if (values.motherEduConsentStatus === undefined) errors.motherEduConsentStatus = 'Required';
	if (values.motherResearchConsentStatus === undefined) errors.motherEduConsentStatus = 'Required';
};