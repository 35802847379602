import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import Login from '../components/Login';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import { useHistory } from 'react-router-dom';
import { CenterLoader } from '../components/CenterLoader';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';

function FormRegister(): JSX.Element {
	const [isNewUser, setNewUser] = useState(false);
	const [loading, setLoading] = useState(false);
	const [updateStatusSweetAlertMessage, setupdateStatusSweetAlertMessage] = useState(null);

	const userState = useFirebaseUserState();
	const history = useHistory();
	const { t } = useTranslation();
	let phoneNumber;
	if (userState.initialized) {
		if(userState.user){
			phoneNumber = userState.user.phoneNumber;
		}
	}
	useEffect(() => {
		if (phoneNumber) {
			firebase.auth().currentUser.getIdToken(/* forceRefresh */)
				.then(idToken => {
					setLoading(true);
					//phoneNumber contains '+' which means space in URL, need to convert to %2B
					fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/getUserInfo?phoneNumber=${encodeURIComponent(phoneNumber)}`, {
						method: 'GET',
						headers: { 'firebase-token': idToken }
					})
						.then((res) => res.json())
						.catch(e => {
							setLoading(false);
							setupdateStatusSweetAlertMessage({
								updateInfoTitle:t('defaultErrorMessageTitle'),
								updateInfoMessage:t('defaultErrorMessage')
							});
						})
						.then((data) => {
							setLoading(false);
							if (Object.keys(data).length === 0) {
								setNewUser(true);
								history.push('/createNewConsent', { 'isNewUser': isNewUser });
							}
							else {
								setNewUser(false);
								history.push('/menuPage', { 'isNewUser':isNewUser });
							}
						})
						.catch(e => {
							setLoading(false);
							setupdateStatusSweetAlertMessage({
								updateInfoTitle:t('defaultErrorMessageTitle'),
								updateInfoMessage:t('defaultErrorMessage')
							});
						})
						.finally(() => {
							if (process.env.NODE_ENV === 'development')
								console.log('getUserInfo isNewUser - ',isNewUser);
						});
				})
				.catch(function (error) {
					// Handle error
					setLoading(false);
					setupdateStatusSweetAlertMessage({
						updateInfoTitle:t('defaultErrorMessageTitle'),
						updateInfoMessage:t('defaultErrorMessage')
					});
					if (process.env.NODE_ENV === 'development')
						console.error('getIdToken:', error);
				});
		}
	}, [phoneNumber,isNewUser]);


	return (
		<>
			{
				(loading || !userState.initialized) && <CenterLoader/>
				
			}
			{
				userState.initialized &&
				<>

					{
						!userState.user &&
						<Login/>
					}
				</>
			}
			{updateStatusSweetAlertMessage &&
				<SweetAlert
					info
					title={updateStatusSweetAlertMessage.updateInfoTitle}
					onConfirm={()=>setupdateStatusSweetAlertMessage(null)}
					onCancel={()=>setupdateStatusSweetAlertMessage(null)}
				>{updateStatusSweetAlertMessage.updateInfoMessage}
				</SweetAlert>
			}

		</>
	);
}

export default FormRegister;
