import React, {useState} from 'react';
import { Dropdown } from 'react-bootstrap';
import personIcon from '../assets/person.svg';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import { signOutCurrentUser } from '../firebase/firebaseUtils';
import { useHistory } from 'react-router-dom';
import { LanguageSelector } from '../components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';

const Header = (): JSX.Element => {
	
	const history = useHistory();
	const userState = useFirebaseUserState();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [updateStatusSweetAlertMessage, setupdateStatusSweetAlertMessage] = useState(null);
	
	const handleOnClickLogout = (event) => {
		setLoading(true);
		//stop the href="#" which destroy react state
		event.preventDefault();
		event.stopPropagation();

		signOutCurrentUser()
			.then(() => {
				//like F5
				//handle user state in app.tsx
				// window.location.pathname = '/';
				setLoading(false);
				history.replace('/');
			})
			.catch(() => {
				//alert('Could not sign out current user.');
				setLoading(false);
				setupdateStatusSweetAlertMessage({
					updateInfoTitle:t('defaultErrorMessageTitle'),
					updateInfoMessage:t('defaultErrorMessage')
				});
			});

		// setAuthenticated(false);
	};

	return (
		
		<div className="container mt-1 mb-xl-0 mb-3">
			<div className="row">
				<div className="col-2 text-break d-flex justify-content-start align-items-center">
					{
						(process.env.NODE_ENV === 'development') &&
						<h6>Dev Env</h6>
					}
						
				</div>
				<div className="col-8 text-break d-flex justify-content-center align-items-center">
					<h3 className="appTitle p-2">
						<strong><a href="/" className="deco-none notranslate">NewbornTime</a></strong>
						{/* <div id="google_translate_element" className="p-2">
						</div> */}
						<LanguageSelector/>
					</h3>
				</div>
				<div className="col-2 text-break d-flex justify-content-end align-items-center">
					{
						(userState.initialized && userState.user) &&
						<Dropdown>
							<Dropdown.Toggle variant="" id="dropdown-basic">
								<img src={personIcon} height="24" data-test="personIcon" />
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item onClick={handleOnClickLogout} data-test='logout'>
									{t('logOutButtonText')}
									{
										loading &&
													<span>
														<span>{' '}</span>
														<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
													</span>
									}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					}	
				
				</div>
			</div>
			
			{/* <div className="d-flex justify-content-center align-items-center">

				{
					(process.env.NODE_ENV === 'development') &&
					<div className="p-2 me-auto ">
						<h6>Dev Env</h6>
					</div>
				}
				
				<div className="p-2 mx-auto">
					<h3 className="appTitle">
						<strong><a href="/" className="deco-none notranslate">NewbornTime</a></strong>
						<div className="p-2" id="google_translate_element"></div>
					</h3>
				</div>
				
				
				{
					(userState.initialized && userState.user) &&
					<div className="p-2 ms-auto">
						<Dropdown>
							<Dropdown.Toggle variant="light" id="dropdown-basic">
								<img src={personIcon} height="24" data-test="personIcon" />
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item href="/viewConsents">View Consents</Dropdown.Item>
								<Dropdown.Item onClick={handleOnClickCreateNewForm} data-test='logout'>Log Out</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				}
				
				
			</div> */}
			{updateStatusSweetAlertMessage &&
				<SweetAlert
					info
					title={updateStatusSweetAlertMessage.updateInfoTitle}
					onConfirm={()=>setupdateStatusSweetAlertMessage(null)}
					onCancel={()=>setupdateStatusSweetAlertMessage(null)}
				>{updateStatusSweetAlertMessage.updateInfoMessage}
				</SweetAlert>
			}

		</div>
	
	);
};

export { Header };
