import firebase from 'firebase';

export const initFirebaseApp = () => {
	// TODO: Replace the following with your app's Firebase project configuration
	// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
	const firebaseConfig = process.env['REACT_APP_FIREBASE_CONFIG'];
	const firebaseConfigObj = JSON.parse(firebaseConfig);

	console.log('Firebase initializin');

	// Initialize Firebase
	!firebase.apps.length ? firebase.initializeApp(firebaseConfigObj) : firebase.app();
	firebase.analytics();


	if (process.env.REACT_APP_DISABLE_FIREBASE_PHONE_AUTH_APP_VERIFICATION) {
		// Turn off phone auth app verification.Only fictional phone numbers can be used then!!!
		firebase.auth().settings.appVerificationDisabledForTesting = true;
		console.log('Firebase phone auth app verification disabled');
	}

	console.log('Firebase initialized');
};

export const signOutCurrentUser = () => firebase.auth().signOut();
