import React from 'react';
import { CreateConsentForm } from '../components/CreateConsentForm';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import Login from '../components/Login';
import { CenterLoader } from '../components/CenterLoader';

const CreateNewConsentPage = (props: any) => {

	
	const userState = useFirebaseUserState();
	
	return (
		<>
			{
				(!userState.initialized) && <CenterLoader/>
			}
			{
				userState.initialized &&
				<>

					{
						!userState.user &&
						<Login />
					}

					{
						userState.user &&
						<CreateConsentForm />
					}
				</>
			}
		</>
		
	);
};

export { CreateNewConsentPage };