import React from 'react';
import newbornTime from '../../assets/Newborn.jpg';
import { useTranslation } from 'react-i18next';

const ConsentInformation = () => {
	const { t } = useTranslation();
	return (
		<div className="container border p-2 p-xl-3" style={{ maxWidth: '90vw' }} >
			<div className="p-1 consentTextAlignment breakUrlLink">
				<h3 className="text-center consentTextHeadingFont">{t('consentInfoTitle')}</h3>

				{/* Section 1 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection1Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection1P1')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection1P2')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection1P3')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection1P4')}</p>


				{/* Section 2 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection2Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection2P1')}</p>
				<img src={newbornTime} className="img-fluid rounded mx-auto d-block mh-70 mt-4" style={{'maxHeight': '40vh'}}/>
				<p className="descriptionFont mt-4">{t('consentInfoSection2P2')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection2P3')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection2P4')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection2P5')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection2P6')}</p>


				{/* Section 3 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection3Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection3P1')}</p>


				{/* Section 4 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection4Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection4P1')}</p>


				{/* Section 5 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection5Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection5P1')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection5P2')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection5P3')}</p>


				{/* Section 6 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection6Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection6P1')}</p>


				{/* Section 7 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection7Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection7P1')}</p>


				{/* Section 8 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection8Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection8P1')}</p>


				{/* Section 9 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection9Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection9P1')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection9P2')}</p>


				{/* Section 10 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection10Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection10P1')}<a href={t('consentInfoSection10P1AnchorHref')}>{t('consentInfoSection10P1AnchorHref')}</a></p>


				{/* Section 11 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection11Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection11P1')} <a href = "mailto:kjersti.engan@uis.no">kjersti.engan@uis.no</a></p>
				<p className="descriptionFont mt-4">{t('consentInfoSection11P2')} <a href = "mailto:siren.irene.rettedal@sus.no">siren.irene.rettedal@sus.no</a></p>
				<p className="descriptionFont mt-4">{t('consentInfoSection11P3')} <a href = "mailto:personverntjenester@nsd.no">personverntjenester@nsd.no</a></p>
				<p className="descriptionFont mt-4">{t('consentInfoSection11P4')} <a href = "mailto:personvernombud@uis.no">personvernombud@uis.no</a></p>
				<p className="descriptionFont mt-4">{t('consentInfoSection11P5')} <a href = "mailto:personvernombud@sus.no">personvernombud@sus.no</a></p>
				<p className="descriptionFont mt-4">{t('consentInfoSection11P6')} <a href = "mailto:postkasse@datatilsynet.no">postkasse@datatilsynet.no</a></p>


				{/* Section 12 Contents */}
				<p className="consentSectionHeadingFont mt-4">{t('consentInfoSection12Heading')}</p>
				<p className="descriptionFont mt-4">{t('consentInfoSection12P1')}</p>
				
				
			</div>
		</div >
	);
};

export { ConsentInformation };