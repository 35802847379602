import firebase from 'firebase';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from 'react-router-dom';
import { ConsentInformation } from '../components/textInformations/ConsentInformation';
import { useTranslation } from 'react-i18next';
import { signOutCurrentUser } from '../firebase/firebaseUtils';
import { CenterLoader } from '../components/CenterLoader';

const UpdateConsentPage = () => {

	const [newMotherResearchConsentStatus, setNewMotherResearchConsentStatus] = useState(null);
	const [newMotherEduConsentStatus, setNewMotherEduConsentStatus] = useState(null);

	const [showSweetAlert, setSweetAlertVisibility] = useState(false);
	const [sweetAlertTitle, setSweetAlertTitle] = useState(null);
	const [sweetAlertMessage, setSweetAlertMessage] = useState(null);

	const [showConsentTextInfoSweetAlert, setConsentTextInfoSweetAlertVisibility] = useState(false);

	const [updateStatusSweetAlertMessage, setupdateStatusSweetAlertMessage] = useState(null);
	const [loading, setLoading] = useState(false);


	const userState = useFirebaseUserState();
	const history = useHistory();
	const { t } = useTranslation();

	const consentIdToEdit = history.location.state['consentIdToEdit'];
	const motherResearchConsentStatusToEdit = history.location.state['motherResearchConsentStatusToEdit'];
	const motherEduConsentStatusToEdit = history.location.state['motherEduConsentStatusToEdit'];
	const isLastActiveResearchConsent = history.location.state['isLastActiveResearchConsent'];


	const phoneNumber = userState.initialized && userState.user && userState.user.phoneNumber;



	const researchConsentEnabledText = t('consentGivenOnlyToResearchText');
	const educationConsentEnabledText = t('consentGivenOnlyToEducationText');
	const researchConsentDisabledText = t('consentToResearchDisabledText');
	const educationConsentDisabledText = t('consentToEducationDisabledText');




	const updateConsent = () => {
		setSweetAlertVisibility(false);
		setLoading(true);
		phoneNumber && firebase.auth().currentUser.getIdToken(/* forceRefresh */)
			.then(idToken => {
				//phoneNumber contains '+' which means space in URL, need to convert to %2B
				
				fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/processMsg`, {
					method: 'POST',
					headers: { 'firebase-token': idToken },
					body: JSON.stringify({
						'phoneNumber': phoneNumber,
						'msgType': 'updateConsent',
						'msgPayload': {
							consentId: consentIdToEdit,
							motherResearchConsentStatus: newMotherResearchConsentStatus,
							motherEduConsentStatus: newMotherEduConsentStatus,
							phoneNumber: phoneNumber,
						}
					})
				}).then(() => {
					if (newMotherResearchConsentStatus === 0 && isLastActiveResearchConsent === 1) {
						// user deleted last active research consent -> All user data will be deleted . So sign out
						signOutCurrentUser()
							.then(() => {
								//like F5
								//handle user state in app.tsx
								// window.location.pathname = '/';
								setLoading(false);
								history.replace('/');
							})
							.catch(() => {
								// show update confirmation alert - as update is success and only sign out is failed
								setLoading(false);
								setupdateStatusSweetAlertMessage({
									updateInfoTitle:t('responseScreenTitle'),
									updateInfoMessage:t('userUpdateResponseText')
								});
								//alert('Could not sign out current user.');
							});
					} else {
						// show update confirmation alert 
						setLoading(false);
						setupdateStatusSweetAlertMessage({
							updateInfoTitle:t('responseScreenTitle'),
							updateInfoMessage:t('userUpdateResponseText')
						});
					}
					
				});
			})
			.catch(function (error) {
				// Handle error
				setLoading(false);
				setupdateStatusSweetAlertMessage({
					updateInfoTitle:t('defaultErrorMessageTitle'),
					updateInfoMessage:t('defaultErrorMessage')
				});
				if (process.env.NODE_ENV === 'development') {
					console.error('getIdToken:', error);
				}
			});
	};

	const handleGiveResearchConsent = () => {

		setNewMotherResearchConsentStatus(1);
		setNewMotherEduConsentStatus(motherEduConsentStatusToEdit);
		setSweetAlertTitle(t('giveConsentToResearchWarningTitle'));
		setSweetAlertMessage(t('consentToResearchCheckboxLabel'));
		setSweetAlertVisibility(true);

	};
	const handleWithdrawResearchConsent = () => {
		setNewMotherResearchConsentStatus(0);
		setNewMotherEduConsentStatus(0);
		setSweetAlertTitle(t('withdrawConsentToResearchWarningTitle'));
		let sweetAlertWarningMessage;
		if (motherEduConsentStatusToEdit) {
			sweetAlertWarningMessage = t('withdrawConsentToResearchWarningTextP1') + t('withdrawConsentToResearchWarningTextP2');
		}
		else {
			sweetAlertWarningMessage = t('withdrawConsentToResearchWarningTextP2');
		}
		if (isLastActiveResearchConsent === 1) {
			sweetAlertWarningMessage = sweetAlertWarningMessage + t('userUpdateResponseAutomaticLogoutText');
		}
		setSweetAlertMessage(sweetAlertWarningMessage);
		setSweetAlertVisibility(true);
	};
	const handleGiveEducationConsent = () => {
		setNewMotherEduConsentStatus(1);
		setNewMotherResearchConsentStatus(1);
		setSweetAlertTitle(t('giveConsentToEducationWarningTitle'));
		setSweetAlertMessage(t('consentToEducationCheckboxLabel'));
		setSweetAlertVisibility(true);
	};
	const handleWithdrawEducationConsent = () => {
		setNewMotherEduConsentStatus(0);
		setNewMotherResearchConsentStatus(motherResearchConsentStatusToEdit);
		setSweetAlertTitle(t('withdrawConsentToEducationWarningTitle'));
		setSweetAlertMessage(t('withdrawConsentToEducationWarningText'));
		setSweetAlertVisibility(true);
	};
	const onShowUpdateInfoToUserSweetAlertClickConfirm = () => {
		setupdateStatusSweetAlertMessage(null);
		history.push('/');
	};

	return (
		<>
			{
				(loading || !userState.initialized) && <CenterLoader/>
			}
			<div>
				<div className='d-flex h-100 flex-column justify-content-center container col-xl-12  col-11 col-md-10 col-lg-8 pb-5 '>
					<div >
						<h3 className="text-center formResponseHeadingFont">{t('editConsentButtonText')}</h3>

						<div className='d-flex flex-column align-items-center justify-content-center'>
							<Button className="p-3 my-3 text-center" variant="primary" onClick={() => setConsentTextInfoSweetAlertVisibility(true)}> {t('viewConsentInformationButtonText')} </Button>
						</div>
						<div className="d-flex flex-column  align-items-center mt-3">
							{

								consentIdToEdit &&
						<div>
							<div className='d-flex flex-column align-items-center consentCardStyle p-4 mt-3'>
								<div className='d-flex flex-column my-2 align-items-center justify-content-center'>
									<div className='my-2 text-center'> {!(motherResearchConsentStatusToEdit) ? researchConsentDisabledText:researchConsentEnabledText} </div>
									<div className='my-2'>
										{
											!(motherResearchConsentStatusToEdit) ?
												<Button className="p-3" variant="primary" onClick={handleGiveResearchConsent}>{t('giveConsentButtonText')}</Button> :
												<Button className="p-3 secondary" variant="secondary" onClick={handleWithdrawResearchConsent}>{t('withDrawConsentButtonText')}</Button>
										}
									</div>
								</div>
							</div>
							<div className='d-flex flex-column align-items-center consentCardStyle p-4 mt-3'>
								<div className='d-flex flex-column mt-2 align-items-center justify-content-center' >
									<div className='my-2 text-center'> {!(motherEduConsentStatusToEdit) ? educationConsentDisabledText:educationConsentEnabledText} </div>
									<div className='my-2'>
										{
											!(motherEduConsentStatusToEdit) ?
												<Button className="p-3" variant="primary" onClick={handleGiveEducationConsent}>{t('giveConsentButtonText')}</Button> :
												<Button className="p-3 secondary" variant="secondary" onClick={handleWithdrawEducationConsent}>{t('withDrawConsentButtonText')}</Button>
										}
									</div>
								</div>
							</div>
						</div>

							}
							{
								!consentIdToEdit &&
						<div className="d-flex justify-content-center m-4">
							<Card className="w-100 consentCardStyle p-4">
								<Card.Body>
									<p className="text-center">{t('noConsentTextP1')}</p>
									<p className="text-center">{t('noConsentTextP2')}</p>
								</Card.Body>
								<div className="d-flex justify-content-center mb-3">
									<Button className="p-3" onClick={() => history.push('/createNewConsent', history.location.state)}>{t('giveConsentButtonText')}</Button>
								</div>
							</Card>
						</div>
							}
						</div>

						<div className="d-flex justify-content-center mt-5">
							<Button className="p-3" onClick={() => history.goBack()}>{t('backButtonText')}</Button>

						</div>
					</div>


					{showSweetAlert &&
				<SweetAlert
					warning
					showCancel
					title={sweetAlertTitle}
					onConfirm={updateConsent}
					onCancel={() => setSweetAlertVisibility(false)}
					cancelBtnText={t('noButtonText')}
					cancelBtnBsStyle="secondary"
					confirmBtnText={t('yesButtonText')}
					confirmBtnBsStyle="primary"

				>{sweetAlertMessage}
				</SweetAlert>
					}
					{showConsentTextInfoSweetAlert &&
				<SweetAlert
					info
					title={t('consentInformationTitle')}
					onConfirm={() => setConsentTextInfoSweetAlertVisibility(false)}
					onCancel={() => setConsentTextInfoSweetAlertVisibility(false)}
				>{<div className='sweetAlertConsentTextInfoHeight'><ConsentInformation /></div>}
				</SweetAlert>
					}
					{updateStatusSweetAlertMessage &&
				<SweetAlert
					info
					title={updateStatusSweetAlertMessage.updateInfoTitle}
					onConfirm={onShowUpdateInfoToUserSweetAlertClickConfirm}
					onCancel={onShowUpdateInfoToUserSweetAlertClickConfirm}
				>{updateStatusSweetAlertMessage.updateInfoMessage}
				</SweetAlert>
					}

				</div>
			</div>
		</>
	);
};

export { UpdateConsentPage };
