import firebase from 'firebase';
import { useEffect, useState } from 'react';

export const useFirebaseUserState = () => {
	// const currentUser = firebase.auth().currentUser;
	// Before the user signs in, the status is initialized: true, user: null
	const [userState, setUserState] = useState<{ initialized: boolean; user: firebase.User }>({initialized: false, user: undefined});

	useEffect(() => {
		const unsubscribe = firebase.auth().onAuthStateChanged(user => {
			setUserState({
				initialized: true,
				user
			});
		});

		return unsubscribe;
	}, []);

	return userState;
};