import firebase from 'firebase';
import React from 'react';
import { useState } from 'react';
import { useFirebaseUserState } from '../firebase/firebaseHook';

const AdminPage = () => {
	const [reply, setReply] = useState<string>();
	const userState = useFirebaseUserState();
	const phoneNumber = userState.initialized && userState.user && userState.user.phoneNumber;


	const deleteUserData = () => {
		firebase.auth().currentUser.getIdToken(/* forceRefresh */)
			.then(idToken => {
				if (phoneNumber) {
					//phoneNumber contains '+' which means space in URL, need to convert to %2B
					fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/deleteUserData?phoneNumber=${encodeURIComponent(phoneNumber)}`, {
						method: 'GET',
						headers: { 'firebase-token': idToken }
					})
						.then(res => res.json())
						.then(data => setReply(JSON.stringify(data)));
				} else {
					console.log('No phoneNumber.');
				}
			})
			.catch(function (error) {
				// Handle error
				if (process.env.NODE_ENV === 'development')
					console.error('getIdToken:', error);
			});
	};

	return (
		<>
			<div>
				<button onClick={deleteUserData} data-test="deleteUserData">Delete my data</button>
			</div>
			{reply && <div>Reply: <div data-test="adminPageReply">{reply}</div></div>}
		</>
	);
};

export { AdminPage };