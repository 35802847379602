import { PersonalInfoFormFields } from './PersonalInfoFormFields';

/**
 * TODO:
 * this method is manually copied to azure function createConsent to validate data!!!
 * move below method to a common npm package
 * @param values 
 * @param knownPartnerTelError error fetched from 3rd party lib(error that could not be found from @function formikValidator)
 * @returns 
 */
export const validatePersonalInfoForm = (values: PersonalInfoFormFields, knownPartnerTelError?: string): PersonalInfoFormFields => {
	const errors = {} as PersonalInfoFormFields;
	if (!values.firstName) {
		errors.firstName = 'Required';
	}

	if (!values.lastName) {
		errors.lastName = 'Required';
	}

	if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	personalNumberValidator(values as PersonalInfoFormFields, errors, knownPartnerTelError);
	return errors;
};

const personalNumberValidator = (values: PersonalInfoFormFields, errors, knownPartnerTelError) => {
	if (!values.nin || values.nin.toString().length != 11) {
		errors.nin = 'Required';
	}else {
		ninValidateGenderAndAge(String(values.nin), errors);
	}
};


/**
 * Calculates the Age at present for a given date
 * Reference : https://www.javatpoint.com/calculate-age-using-javascript
 *
 * @param dob
 * @returns age
 */
const calculateAge = (dob: Date) => {
	const millisecDelta = Date.now() - dob.getTime();
	const yearDelta = millisecDelta / 31536000000; //(365 * 24 * 60 * 60 * 1000)
	const age = Math.floor(yearDelta);
	return age;
};


/**
 * Validates nin based on the two conditions: 1) Should be a female 2) Age Between 10 to 60
 * Reference : https://www.skatteetaten.no/en/person/national-registry/birth-and-name-selection/children-born-in-norway/national-id-number/
 *
 * @param nin
 * @param errors errors
 * @returns errors
 */
const ninValidateGenderAndAge = (nin: string, errors) => {
	const AGE_LOWER_LIMIT = 10;
	const AGE_UPPER_LIMIT = 60;
	const date = Number(nin.substring(0, 2));
	const month = Number(nin.substring(2, 4));
	const yearLastTwoDigits = nin.substring(4, 6);
	const ninIndividualNumber = Number(nin.substring(6, 9));
	const ninGender = Number(nin[8]);
	let yearFirstTwoDigit = '';
	if (ninGender % 2 != 0) {
		// 'men'
		errors.nin = 'Only Women are allowed';
	}
	if (
		ninIndividualNumber <= 749 &&
		ninIndividualNumber > 500 &&
		Number(yearLastTwoDigits) >= 54 &&
		Number(yearLastTwoDigits) <= 99
	) {
		//'1854- 1899'
		yearFirstTwoDigit = '18';
	} else if (ninIndividualNumber <= 499 && ninIndividualNumber > 0) {
		//'1900- 1999'
		yearFirstTwoDigit = '19';
	} else if (ninIndividualNumber <= 999 && ninIndividualNumber > 900) {
		// '1940- 1999'
		yearFirstTwoDigit = '19';
	} else if (
		ninIndividualNumber <= 999 &&
		ninIndividualNumber > 500 &&
		Number(yearLastTwoDigits) >= 0 &&
		Number(yearLastTwoDigits) <= 39
	) {
		// '2000- 2039'
		yearFirstTwoDigit = '20';
	} else {
		errors.nin = 'unknown individual number';
	}
	if(yearFirstTwoDigit && yearFirstTwoDigit.length>0){
		const fullYear = Number(yearFirstTwoDigit + yearLastTwoDigits);
		const age = calculateAge(new Date(fullYear, month, date));
		if (age < AGE_LOWER_LIMIT || age > AGE_UPPER_LIMIT) {
			errors.nin = 'Age is Invalid';
		}
	}
};