import React from 'react';
import Loader from 'react-loader-spinner';


const CenterLoader = () => {
	return (
		<div className='position-fixed h-75 loading-layer d-flex justify-content-center align-items-center'>
			<Loader type="ThreeDots" color="#2BAD60" height="150" width="150" />
		</div>
	);
};

export { CenterLoader };