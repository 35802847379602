import firebase from 'firebase';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CenterLoader } from '../components/CenterLoader';
import SweetAlert from 'react-bootstrap-sweetalert';

interface BabyLinkedConsentDTO {
	id: number,
	babyId: number,
	babyBornTime: string,
	consentCreatedTime: string,
	consentUpdatedTime: string,
	motherResearchConsentStatus: 0 | 1,
	motherEduConsentStatus: 0 | 1,
}

interface BabyLinkedConsent {
	[key: string]: BabyLinkedConsentDTO[]
}

const ViewConsentListPage = () => {
	const [consents, setConsents] = useState<BabyLinkedConsent>();
	const [shouldReFetchData, setShouldReFetchData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [updateStatusSweetAlertMessage, setupdateStatusSweetAlertMessage] = useState(null);

	const userState = useFirebaseUserState();
	const history = useHistory();
	const {t} = useTranslation();

	


	const phoneNumber = userState.initialized && userState.user && userState.user.phoneNumber;

	const researchConsentText = t('consentGivenOnlyToResearchText');
	const researchAndEducationConsentText = t('consentGivenToResearchAndEducationText');
	const educationConsentText = t('consentGivenOnlyToEducationText');
	const bothConsentWithdrawn = t('consentWithdrawnForBothResearchAndEducationText');

	useEffect(() => {
		if (shouldReFetchData === false) return;

		phoneNumber && firebase.auth().currentUser.getIdToken(/* forceRefresh */)
			.then(idToken => {
				setLoading(true);

				//phoneNumber contains '+' which means space in URL, need to convert to %2B
				fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/GetBabyLinkedConsents?phoneNumber=${encodeURIComponent(phoneNumber)}`, {
					method: 'GET',
					headers: { 'firebase-token': idToken }
				})
					.then(res => res.json())
					.catch(e => {
						//alert('error submitting.');
						setLoading(false);
						setupdateStatusSweetAlertMessage({
							updateInfoTitle:t('defaultErrorMessageTitle'),
							updateInfoMessage:t('defaultErrorMessage')
						});
					})
					.then(data => {
						processFetchedData(data);
						setShouldReFetchData(false);
					})
					.catch(e => {
						//alert('error submitting.');
						setLoading(false);
						setupdateStatusSweetAlertMessage({
							updateInfoTitle:t('defaultErrorMessageTitle'),
							updateInfoMessage:t('defaultErrorMessage')
						});
					})
					.finally(() => setLoading(false));
			})
			.catch(function (error) {
				// Handle error
				setLoading(false);
				setupdateStatusSweetAlertMessage({
					updateInfoTitle:t('defaultErrorMessageTitle'),
					updateInfoMessage:t('defaultErrorMessage')
				});
				if (process.env.NODE_ENV === 'development')
					console.error('getIdToken:', error);
			});
	}, [phoneNumber, shouldReFetchData]);

	
	const processFetchedData = (consents: BabyLinkedConsentDTO[]) => {
		const groupedByConsentId = _.groupBy(
			consents.filter(c => c.motherResearchConsentStatus === 1),
			c => c.consentCreatedTime);
		setConsents(groupedByConsentId);
	};


	const handleEditConsentClick = (consentIdToEdit, motherResearchConsentStatusToEdit, motherEduConsentStatusToEdit) => {
		history.push('/updateConsent', {
			'consentIdToEdit': consentIdToEdit,
			'motherResearchConsentStatusToEdit': motherResearchConsentStatusToEdit,
			'motherEduConsentStatusToEdit': motherEduConsentStatusToEdit,
			'isLastActiveResearchConsent' : (consents && Object.values(consents).length  > 1)?0:1
		});
	};

	const onShowUpdateInfoToUserSweetAlertClickConfirm = () => {
		setupdateStatusSweetAlertMessage(null);
		history.push('/');
	};

	
	const mysqlTimestampToJavascriptUtcDateTimeObject = (timeStampString) => {
		// Split timestamp into [ Y, M, D, h, m, s ]
		const t = timeStampString.split(/[- :]/);
		// Apply each element to the Date function
		const d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
		return d;
	};

	
	return (
		<>
			{
				(loading || !userState.initialized) && <CenterLoader/>
			}
			{ (!loading  && userState.initialized) &&
			<div>
				<div className='d-flex h-100 flex-column justify-content-center container pb-5 '>
					<h3 className="text-center formResponseHeadingFont">{t('viewConsentListPageTitle')}</h3>

					<div className="d-flex flex-column  align-items-center mt-3">
						{
							consents && Object.values(consents).length !== 0 && Object.entries(consents).map((cs, index) => {
								return (
									<div key={index} className='d-flex flex-column align-items-center consentCardStyle p-4 mt-3'>
										<div className='d-flex my-2'>
											<div className='mx-2'>{t('consentGivenDateLabel')}</div>
											<div className='mx-2'> {mysqlTimestampToJavascriptUtcDateTimeObject(cs[1][0]?.consentCreatedTime).toLocaleString('no-NO')}</div>
										</div>
										<div className="text-center my-3 ">
											{
												(cs[1][0]?.motherResearchConsentStatus && !cs[1][0]?.motherEduConsentStatus) ?
													researchConsentText :
													(!cs[1][0]?.motherResearchConsentStatus) && (cs[1][0]?.motherEduConsentStatus) ?
														educationConsentText :
														(cs[1][0]?.motherResearchConsentStatus) && (cs[1][0]?.motherEduConsentStatus) ?
															researchAndEducationConsentText : bothConsentWithdrawn

											}
										</div>
										<div className="text-center my-3">
											<Button className="p-3" variant="primary" onClick={() => handleEditConsentClick(cs[1][0]?.id, cs[1][0]?.motherResearchConsentStatus, cs[1][0]?.motherEduConsentStatus)}>{t('editConsentButtonText')}</Button>
										</div>

										<div className='d-flex mt-2'>

											<div className='mx-2'><small>{t('consentLastUpdatedDateLabel')}</small></div>
											<div className='mx-2'><small> {mysqlTimestampToJavascriptUtcDateTimeObject(cs[1][0]?.consentUpdatedTime).toLocaleString('no-NO')}</small></div>

										</div>
									</div>
								);
							})
						}
						{
							consents && Object.values(consents).length === 0 &&
							<div className="d-flex justify-content-center m-4">
								<Card className="w-100 consentCardStyle p-4">
									<Card.Body>
										<p className="text-center">{t('noConsentTextP1')}</p>
										<p className="text-center">{t('noConsentTextP2')}</p>
									</Card.Body>
									<div className="d-flex justify-content-center mb-3">
										<Button className="p-3" onClick={() => history.push('/createNewConsent', history.location.state)}>{t('giveConsentButtonText')}</Button>
									</div>
								</Card>
							</div>
						}
					</div>

					<div className="d-flex justify-content-center my-4">
						<Button className="p-3" onClick={() => history.goBack()}>{t('backButtonText')}</Button>

					</div>
				</div>
			</div>
			}
			{updateStatusSweetAlertMessage &&
				<SweetAlert
					info
					title={updateStatusSweetAlertMessage.updateInfoTitle}
					onConfirm={onShowUpdateInfoToUserSweetAlertClickConfirm}
					onCancel={onShowUpdateInfoToUserSweetAlertClickConfirm}
				>{updateStatusSweetAlertMessage.updateInfoMessage}
				</SweetAlert>
			}

		</>
	);
};

export { ViewConsentListPage };
