import React from 'react';
import bityogaLogo from '../assets/bityoga.png';
import laerdalLogo from '../assets/laerdal-logo.png';
import helseLogo from '../assets/helse-logo.png';
import uisLogo from '../assets/uis-logo.png';


const Footer = (): JSX.Element => {
	


	return (
		
		<div className="container mt-1 mb-1">
			{/* <div className="row ">
				<div className="col-3 d-flex align-items-center justify-content-center">
					<img src={uisLogo} className="img-fluid footerLogoImage" />
				</div>
				<div className="col-3 d-flex align-items-center justify-content-center">
					<img src={helseLogo} className="img-fluid footerLogoImage" />
				</div>
				<div className="col-3 d-flex align-items-center justify-content-center">
					<img src={laerdalLogo} className="img-fluid footerLogoImage" />
				</div>
				<div className="col-3 d-flex align-items-center justify-content-center">
					<img src={bityogaLogo} className="img-fluid footerLogoImage" />
				</div>
			</div> */}
			<div className="d-flex justify-content-between align-items-center ">
				<div className="p-2"><img src={uisLogo} className="img-fluid footerLogoImage"  /></div>
				<div className="p-2"><img src={helseLogo}  className="img-fluid footerLogoImage" /></div>
				<div className="p-2"><img src={laerdalLogo} className="img-fluid footerLogoImage"  /></div>
				<div className="p-2"><img src={bityogaLogo} className="img-fluid footerLogoImage"  /></div>
			</div>
		</div>

		
	);
};

export { Footer };
