import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import { signOutCurrentUser } from '../firebase/firebaseUtils';
import { useTranslation } from 'react-i18next';
import { CenterLoader } from '../components/CenterLoader';
import SweetAlert from 'react-bootstrap-sweetalert';

const FormResponse = (props: any) => {
	// const { authenticated, setAuthenticated } = props;

	const history = useHistory();
	const userState = useFirebaseUserState();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [updateStatusSweetAlertMessage, setupdateStatusSweetAlertMessage] = useState(null);

	const handleOnClickEditForm = (event) => {
		//stop the href="#" which destroy react state
		event.preventDefault();
		event.stopPropagation();
		//keep all state
		history.goBack();
	};

	const handleOnClickCloseWindow = (event) => {
		//stop the href="#" which destroy react state
		setLoading(true);
		event.preventDefault();
		event.stopPropagation();
		signOutCurrentUser()
			.then(() => {
				//like F5
				//handle user state in app.tsx
				// window.location.pathname = '/';
				setLoading(false);
				history.replace('/');
			})
			.catch(() => {
				//alert('Could not sign out current user.');
				setLoading(false);
				setupdateStatusSweetAlertMessage({
					updateInfoTitle:t('defaultErrorMessageTitle'),
					updateInfoMessage:t('defaultErrorMessage')
				});
			});
	};

	return (
		<>
			{
				(loading || !userState.initialized) && <CenterLoader/>
			}
			{
				userState.initialized && userState.user &&
				<div>
					<div className='d-flex h-100 flex-column justify-content-center container col-sm-12 col-md-10 col-lg-8 pb-5 '>
						<div className="p-3">
							<p className='formResponseHeadingFont text-center'>{t('responseScreenTitle')}</p>
						
							<p className='formResponseDescriptionFons mt-5'>{t('responseScreenTextP1')}</p>
							<p className='formResponseDescriptionFons'>{t('responseScreenTextP2')}</p>

							<div className="d-flex justify-content-center align-items-center">
								<div className="p-2 my-4" data-test='smsCodeDiv'>
									<button type="button" className="btn btn-primary" onClick={handleOnClickEditForm} data-test='EditYourFormA'>{t('editButtonText')}</button>
								
								</div>
								<div className="p-2 my-4" data-test='smsCodeDiv'>
									<button type="button" className="btn btn-primary" onClick={handleOnClickCloseWindow}  data-test='createNewFormA'>{t('closeButtonText')}</button>
								</div>
							</div>
					
						</div>
					</div>
				</div>
			}
			{updateStatusSweetAlertMessage &&
				<SweetAlert
					info
					title={updateStatusSweetAlertMessage.updateInfoTitle}
					onConfirm={()=>setupdateStatusSweetAlertMessage(null)}
					onCancel={()=>setupdateStatusSweetAlertMessage(null)}
				>{updateStatusSweetAlertMessage.updateInfoMessage}
				</SweetAlert>
			}
		</>
	);
};

export { FormResponse };