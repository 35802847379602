import React from 'react';
import { useTranslation } from 'react-i18next';

const LoginScreenTextInformation = () => {

	const { t } = useTranslation();
	return (
		<div className="row" id="loginScreenText">
			<p className="my-2 text-justify descriptionFont">{t('loginScreenTextP1')}</p>
			<p className="my-2 text-justify descriptionFont">{t('loginScreenTextP2')} <a href={t('loginScreenTextP2AnchorHref')}>{t('loginScreenTextP2Anchor')}</a></p>
			<p className="my-2 text-justify descriptionFont">{t('loginScreenTextP3')}</p>
		</div>
	);
};

export { LoginScreenTextInformation };