import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import i18n from 'i18next';

const LanguageSelector = (props: any) => {
	// const { changeLanguage } = props;
	const [lang, setLang] = useState('no');

	const changeLanguage = (element) => {
		const v = element.target.value;
		i18n.changeLanguage(v);
		setLang(v);
	};

	return (
		<select className="form-select" value={lang} onChange={(element) =>changeLanguage(element)}>
			<option value='no'>Norsk</option>
			<option value="en">English</option>
		</select>

	);
};

export { LanguageSelector };