import React, { useEffect, useState } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import 'bootstrap/dist/css/bootstrap.css';
//import ReactCodeInput from 'react-code-input';
import ReactCodeInput from 'react-verification-code-input';

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics';

// Add the Firebase products that you want to use
import 'firebase/auth';

import { useTranslation } from 'react-i18next';

import newbornTime from '../assets/Newborn.jpg';

import { LoginScreenTextInformation } from './textInformations/LoginScreenTextInformation';
import SweetAlert from 'react-bootstrap-sweetalert';

function Login(): JSX.Element {
	const [phoneNumber, setPhoneNumber] = useState();
	const [smsCodeOk, setSmsCodeOk] = useState(undefined);
	const [smsSent, setSmsSent] = useState(false);
	const [smsResendTimer, setSmsResendTimer] = useState(0);
	const [resendSmsCode, setResendSmsCode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [smsCodeVerifierLoading, setSmsCodeVerifierLoading] = useState(false);
	const [sendSmsErrorMessage, setSendSmsErrorMessage] = useState(null);

	const { t } = useTranslation();




	useEffect(() => {
		let interval = null;
		if (smsResendTimer > 0) {
			interval = setInterval(() => {
				setSmsResendTimer(smsResendTimer => smsResendTimer - 1);
			}, 1000);
		} else if (smsResendTimer == 0) {

			clearInterval(interval);
		}
		return () => {

			clearInterval(interval);
		};
	}, [smsResendTimer]);

	function setRecaptchaVerifier(recaptchaVerifierContainer) {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptchaVerifierContainer, {
			'size': 'invisible',
			'callback': (response: unknown) => {
				// reCAPTCHA solved, allow signInWithPhoneNumber.
				// onSignInSubmit();
				if (process.env.NODE_ENV === 'development')
					console.log('recaptchaVerifier:' + response);
			},
			'expired-callback': (response) => {
				// Response expired. Ask user to solve reCAPTCHA again.
				// ...
				if (process.env.NODE_ENV === 'development')
					console.log('recaptchaVerifier(expired-callback):' + +response);
			}
		});
	}

	const onPhoneNumberChange = (status, value, countryData, number) => {
		if (status === true) {
			setPhoneNumber(number.replaceAll(' ', ''));
		} else {
			setPhoneNumber(null);
		}
	};

	const sendSms = () => {
		console.log('sendSms method called');
		setLoading(true);
		startResendSmsCountdownTimer();
		console.log('phoneNumber:' + phoneNumber);
		const appVerifier = window.recaptchaVerifier;
		firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
			.then((confirmationResult) => {
				//TODO: move out of this then()?
				setSmsSent(true);
				setLoading(false);
				// SMS sent. Prompt user to type the code from the message, then sign the
				// user in with confirmationResult.confirm(code).
				window.confirmationResult = confirmationResult;

			}).catch((error) => {
				// resetResendSmsCountdownTimer();
				//TODO: show some error to user?
				
				// Reference : https://firebase.google.com/docs/reference/js/v8/firebase.auth.PhoneAuthProvider
				console.log('sendSms error:' + error.code + ' ' + error.message	);
				setLoading(false);
				if (error.code) {
					switch (error.code) {
					// Thrown if the reCAPTCHA response token was invalid, expired, or if this method was called from a non-whitelisted domain.
					case 'auth/captcha-check-failed':
						setSendSmsErrorMessage(
							{
								sendSmsErrorSweetAlertTitle: t('smsSendErrorCaptchaCheckFailedMessageTitle'),
								sendSmsErrorSweetAlertMessage : t('smsSendErrorCaptchaCheckFailedMessage'),
							});
						break;
					// Thrown if the phone number has an invalid format.
					case 'auth/invalid-phone-number':
						setSendSmsErrorMessage(
							{
								sendSmsErrorSweetAlertTitle: t('smsSendErrorInvalidNumberMessageTitle'),
								sendSmsErrorSweetAlertMessage : t('smsSendErrorInvalidNumberMessage'),
							});
						break;
					// Thrown if the phone number is missing.
					case 'auth/missing-phone-number':
						setSendSmsErrorMessage(
							{
								sendSmsErrorSweetAlertTitle: t('smsSendErrorInvalidNumberMessageTitle'),
								sendSmsErrorSweetAlertMessage : t('smsSendErrorInvalidNumberMessage'),
							});
						break;
					// Thrown if the SMS quota for the Firebase project has been exceeded.
					case 'auth/quota-exceeded':
						setSendSmsErrorMessage(
							{
								sendSmsErrorSweetAlertTitle: t('smsSendErrorDefaultMessageTitle'),
								sendSmsErrorSweetAlertMessage : t('smsSendErrorDefaultMessage'),
							});
						break;
					// Thrown if the user corresponding to the given phone number has been disabled.
					case 'auth/user-disabled':
						setSendSmsErrorMessage(
							{
								sendSmsErrorSweetAlertTitle: t('smsSendErrorDefaultMessageTitle'),
								sendSmsErrorSweetAlertMessage : t('smsSendErrorDefaultMessage'),
							});
						break;

					default:
						setSendSmsErrorMessage(
							{
								sendSmsErrorSweetAlertTitle: t('smsSendErrorDefaultMessageTitle'),
								sendSmsErrorSweetAlertMessage : t('smsSendErrorDefaultMessage'),
							});
						break;
					}
				} else {
					setSendSmsErrorMessage(
						{
							sendSmsErrorSweetAlertTitle: t('smsSendErrorDefaultMessageTitle'),
							sendSmsErrorSweetAlertMessage : t('smsSendErrorDefaultMessage'),
						});
				}
				

				if (process.env.NODE_ENV === 'development')
					console.error('signInWithPhoneNumber:' + (error.code)?error.code:error);
				// Error; SMS not sent
				// ...
			});
	};

	const onClickGetSms = () => {

		setRecaptchaVerifier('send-sms-button');
		sendSms();
	};


	const onCompleteSmsCodeInput = (val) => {
		// setSmsCodeOk(false);
		setSmsCodeVerifierLoading(true);
		window.confirmationResult.confirm(val)
			.then(result => {
				setSmsCodeVerifierLoading(false);
				//user authenticated
				if (process.env.NODE_ENV === 'development')
					console.log('confirmationResult:', result);
			})
			.catch(e => {
				setSmsCodeVerifierLoading(false);
				setSmsCodeOk(false);

				if (process.env.NODE_ENV === 'development')
					console.error('confirmationResult:', e);
			});
	};

	const onKeyPressIntlTelInput = (e) => {
		if (e.code === 'Enter' || e.code === 'NumpadEnter')
			if (phoneNumber) {
				onClickGetSms();
			}
	};
	const startResendSmsCountdownTimer = () => {
		setSmsResendTimer(30);
	};

	const resetPhoneNumberAuthenticator = () => {
		setResendSmsCode(false);
		window.recaptchaVerifier = window.recaptchaVerifier.clear();
		setSmsCodeOk(undefined);
		setSmsSent(false);
		setSmsResendTimer(0);
	};

	const onClickResendSms = () => {
		setResendSmsCode(true);
		window.recaptchaVerifier = window.recaptchaVerifier.clear();
		setSmsCodeOk(undefined);
		setSmsSent(false);
		setSmsResendTimer(0);
		setRecaptchaVerifier('resend-sms-button');
		sendSms();
	};


	return (
		<div className="container">
			<div className="row align-items-center">
				<div className="col-xl-6">
					<img src={newbornTime} className="img-fluid" />
				</div>
				<div className="col-xl-6 p-xl-5 mt-2 mt-xl-0">
					<LoginScreenTextInformation />
					{
						(!smsSent && !resendSmsCode) &&
						<div className="row">
							<div className="container">
								<div className="row">
									<p className="my-2 text-left labelFont">{t('mobileNumberLabel')}</p>
								</div>
								<div id="phone-number-div" className="row">

									<div onKeyPress={onKeyPressIntlTelInput} data-test='loginTelSpan'>
										<IntlTelInput
											preferredCountries={['no']}
											containerClassName="intl-tel-input w-100"
											inputClassName="form-control"
											onPhoneNumberChange={onPhoneNumberChange}
											autoFocus
										/>
									</div>
									{

										<div className="">
											<button id="send-sms-button" type="button" className="btn btn-primary" onClick={onClickGetSms} disabled={!phoneNumber} data-test='getSMSButton'>{t('getCodeButtonText')}
												{
													loading &&
													<span>
														<span>{' '}</span>
														<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
													</span>
												}
											</button>
										</div>
									}
								</div>
							</div>
						</div>
					}




					{
						(smsSent || resendSmsCode) &&
						<div className="row">
							<div className="container">
								<div className="row">
									<strong className="my-2 text-center labelFont">{t('smsConfirmationTextP1')}</strong>
									<p className="my-2 text-center labelFont">{t('smsConfirmationTextP2')} {phoneNumber}</p>
								</div>
								<div className="row">

									<div className="d-flex flex-column justify-content-center align-items-center m-2" data-test='smsCodeDiv'>
										<ReactCodeInput
											type='number'
											fields={6}
											fieldWidth={40}
											fieldHeight={40}
											className={smsCodeOk === false ? 'error-shake-effect' : ''}
											onChange={v => {
												setSmsCodeOk(null);
											}}
											onComplete={onCompleteSmsCodeInput}
										/>
										{
											smsCodeVerifierLoading &&
											<span className="spinner-border m-1 spinner-border-sm" role="status" aria-hidden="true"> </span>		
										}
										{smsCodeOk === false &&
											<span className='text-danger m-1'>{t('invalidSmsCodeWarningText')}</span>}
									</div>
								</div>
								<div className="row mt-4">
									<div className="d-flex justify-content-center align-items-center ">
										<div className="p-2" data-test='smsCodeDiv'>
											{/* <button type="button" className="btn btn-primary" onClick={onClickResendSms} disabled={!(smsResendTimer == 0)} data-test='getSMSButton'>Send kode på nytt {!(smsResendTimer == 0) && '( ' + smsResendTimer + ' ) s'}</button> */}
											<button id="resend-sms-button" type="button" className="btn btn-primary" onClick={onClickResendSms} disabled={!(smsResendTimer == 0)} data-test='getSMSButton'>{t('resendSmsCodeButtonText')}
												{
													loading &&
													<span>
														<span>{' '}</span>
														<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
													</span>
												}
											</button>
										</div>
										<div className="p-2" data-test='smsCodeDiv'>
											<button type="button" className="btn btn-primary" onClick={resetPhoneNumberAuthenticator} disabled={!phoneNumber}>{t('changeNumberButtonText')}</button>
										</div>
									</div>
								</div>
								{
									!(smsResendTimer == 0) &&
									<div className="row mt-4">
										<p className="text-break d-flex justify-content-center align-items-center labelFont">{t('resendSmsCodeTextP1')} <span className="notranslate">{smsResendTimer}</span> {t('resendSmsCodeTextP2')}</p>
									</div>
								}
							</div>
						</div>
					}



				</div>
			</div>
			{sendSmsErrorMessage &&
				<SweetAlert
					info
					title={sendSmsErrorMessage.sendSmsErrorSweetAlertTitle}
					onConfirm={()=>setSendSmsErrorMessage(null)}
					onCancel={()=>setSendSmsErrorMessage(null)}
				>{sendSmsErrorMessage.sendSmsErrorSweetAlertMessage}
				</SweetAlert>
			}
		</div >

	);
}

export default Login;
