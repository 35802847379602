import React from 'react';
import { ConsentInformation } from '../components/textInformations/ConsentInformation';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import Login from '../components/Login';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CenterLoader } from '../components/CenterLoader';

const ViewConsentInformation = (props: any) => {
	const userState = useFirebaseUserState();
	const history = useHistory();
	const {t} = useTranslation();
	
	return (
		<>
			{
				(!userState.initialized) && <CenterLoader/>
			}
			{
				userState.initialized &&
				<>

					{
						!userState.user &&
						<Login />
					}

					{
						userState.user &&
						<div className="d-flex flex-column justify-content-center container align-items-center col-sm-12 col-md-10 col-lg-8 pb-5 mt-2">
							<div>
								<ConsentInformation />
							</div>
							<div className="text-center mb-3">
								<button type="button" className="btn btn-primary mt-4 p-3" onClick={() => history.goBack()} data-test='CreateNewConsentMenuButton'>{ t('backButtonText')}</button>
							</div>
						</div>
					}
				</>
			}
		</>
		
	);
};

export { ViewConsentInformation };