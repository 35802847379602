import React from 'react';
import { UserDataForm } from '../components/UserDataForm';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import Login from '../components/Login';
import { CenterLoader } from '../components/CenterLoader';

const UserPersonalInfoPage = (props: any) => {

	
	const userState = useFirebaseUserState();
	
	return (
		<>
			{
				!userState.initialized && <CenterLoader/>
			}
			{
				userState.initialized &&
				<>

					{
						!userState.user &&
						<Login />
					}

					{
						userState.user &&
						<UserDataForm />
					}
				</>
			}
		</>
	);
};

export { UserPersonalInfoPage };