import React, { useEffect } from 'react';
import {
	BrowserRouter, Route, Switch, useHistory
} from 'react-router-dom';
import './App.css';
import { Header } from './pages/Header';
import { Footer } from './pages/Footer';
import { initFirebaseApp } from './firebase/firebaseUtils';
import FormRegister from './pages/FormRegister';
import { FormResponse } from './pages/FormResponse';
import { AdminPage } from './pages/AdminPage';
import { MenuPage } from './pages/MenuPage';
import { useFirebaseUserState } from './firebase/firebaseHook';
import { ViewConsentListPage } from './pages/ViewConsentListPage';
import { UpdateConsentPage } from './pages/UpdateConsentPage';
import { CreateNewConsentPage } from './pages/CreateNewConsentPage';
import { UserPersonalInfoPage } from './pages/UserPersonalInfoPage';
import { ViewConsentInformation } from './pages/ViewConsentInformationPage';

function App(): JSX.Element {
	//need to init firebase before using!!!
	initFirebaseApp();
	
	const history = useHistory();
	const userState = useFirebaseUserState();

	useEffect(() => {
		if (userState.initialized && !userState.user) {
			history && history.push('/');
		}
	}, [userState]);

	return (
		<BrowserRouter basename="/consent">
			<header>
				<Header/>
			</header>
			<main className="mb-3 d-flex justify-content-center align-items-xl-center">
				
				{/* A <Switch> looks through its children <Route>s and
			  renders the first one that matches the current URL. */}
				<Switch>				
						
						
					<Route path="/adminy>n3gJ3dNkEGQ5rA">
						<AdminPage />
					</Route>
					<Route path="/formResponse">
						<FormResponse />
					</Route>
					<Route path="/userPersonalInfo">
						<UserPersonalInfoPage />
					</Route>
					<Route path="/updateConsent">
						<UpdateConsentPage />
					</Route>
					<Route path="/viewConsents">
						<ViewConsentListPage />
					</Route>
					<Route path="/createNewConsent">
						<CreateNewConsentPage />
					</Route>
					<Route path="/viewConsentInformation">
						<ViewConsentInformation />
					</Route>
					<Route path="/menuPage">
						<MenuPage />
					</Route>
					<Route path="/">
						<FormRegister />
					</Route>
				</Switch>
				
			</main>
			<footer>
				<Footer />
			</footer>
		</BrowserRouter>
	);
}

export default App;
