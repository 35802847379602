import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import { CenterLoader } from '../components/CenterLoader';


const MenuPage = (props: any) => {
	// const { authenticated, setAuthenticated } = props;

	const history = useHistory();
	const userState = useFirebaseUserState();
	const { t } = useTranslation();


	const handleOnClickEditConsent = (event) => {
		//stop the href="#" which destroy react state
		event.preventDefault();
		event.stopPropagation();
		//keep all state
		history.push('/viewConsents', history.location.state);
	};

	const handleOnClickEditPersonalInfo = (event) => {
		//stop the href="#" which destroy react state
		event.preventDefault();
		event.stopPropagation();
		//keep all state
		history.push('/userPersonalInfo', history.location.state);
	};
	const handleOnClickCreateNewConsent = (event) => {
		//stop the href="#" which destroy react state
		event.preventDefault();
		event.stopPropagation();

		//keep all state
		history.push('/createNewConsent', history.location.state);
	};



	return (
		<>
			{
				!userState.initialized &&<CenterLoader/>
			}
			{
				userState.initialized && userState.user &&
				<div>
					<div className='d-flex h-100 flex-column justify-content-center pb-5'>

						<button type="button" className="btn btn-primary my-4 p-4" onClick={handleOnClickCreateNewConsent} data-test='CreateNewConsentMenuButton'>{t('giveConsentButtonText')}</button>
						<button type="button" className="btn btn-primary my-4 p-4" onClick={handleOnClickEditConsent} data-test='EditConsentMenuButton'>{t('viewOrEditConsentButtonText')}</button>
						<button type="button" className="btn btn-primary my-4 p-4" onClick={handleOnClickEditPersonalInfo} data-test='EditPersonalInfoMenuButton'>{t('viewOrEditPersonalInfoButtonText')}</button>

					</div>
				</div>

			}
		</>
	);
};

export { MenuPage };