import firebase from 'firebase';
import _, { parseInt } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import { PersonalInfoFormFields } from './formValidator/userPersonalInfoFormValidator/PersonalInfoFormFields';
import { validatePersonalInfoForm } from './formValidator/userPersonalInfoFormValidator/PersonalInfoFormValidator';
import { validateConsentForm } from './formValidator/consentFormValidator/ConsentFormValidator';
import { useTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CenterLoader } from './CenterLoader';

const UserDataForm = (): JSX.Element => {
	const [validFormData, setValidFormData] = useState(null);
	const [valueState, setValueState] = useState<Partial<PersonalInfoFormFields>>({});
	const [errorState, setErrorState] = useState<Partial<PersonalInfoFormFields>>({});
	const [touchedState, setTouchedState] = useState<Partial<Record<keyof (PersonalInfoFormFields), boolean>>>({});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [confirmFormSubmit, setconfirmFormSubmit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [isFormEditable, setIsformEditable] = useState(true);
	const [updateStatusSweetAlertMessage, setupdateStatusSweetAlertMessage] = useState(null);

	const history = useHistory();
	const userState = useFirebaseUserState();
	const { t } = useTranslation();

	console.log('The app is starting...');
	console.log('userState: ' + userState);

	const phoneNumber = userState.initialized && userState.user.phoneNumber;

	useEffect(() => {
		if (phoneNumber) {
			firebase.auth().currentUser.getIdToken(/* forceRefresh */)
				.then(idToken => {
					setLoading(true);

					//phoneNumber contains '+' which means space in URL, need to convert to %2B
					fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/getUserInfo?phoneNumber=${encodeURIComponent(phoneNumber)}`, {
						method: 'GET',
						headers: { 'firebase-token': idToken }
					})
						.then((res) => res.json())
						.catch(e => {
							setLoading(false);
							setupdateStatusSweetAlertMessage({
								updateInfoTitle:t('defaultErrorMessageTitle'),
								updateInfoMessage:t('defaultErrorMessage')
							});
						})
						.then((data) => {
							setValueState(data);
						})
						.catch(e => {
							setLoading(false);
							setupdateStatusSweetAlertMessage({
								updateInfoTitle:t('defaultErrorMessageTitle'),
								updateInfoMessage:t('defaultErrorMessage')
							});
						})
						.finally(() => setLoading(false));
				})
				.catch(function (error) {
					// Handle error
					setLoading(false);
					setupdateStatusSweetAlertMessage({
						updateInfoTitle:t('defaultErrorMessageTitle'),
						updateInfoMessage:t('defaultErrorMessage')
					});
					if (process.env.NODE_ENV === 'development')
						console.error('getIdToken:', error);
				});
		}
	}, [phoneNumber]);

	useEffect(() => {
		if ( history.location.state['isNewUser']) {
			if (validFormData) {
				createNewConsentAndUserProfile();
			}
		}
		else {
			if(validFormData) {
				updateUserProfileInfo();
			}
		}
	}, [validFormData]);

	const updateUserProfileInfo = () => {
		setLoading(true);
		firebase.auth().currentUser.getIdToken(/* forceRefresh */)
			.then(function (idToken) {
				// Send token to your backend via HTTPS
				if (process.env.NODE_ENV === 'development')
					console.log('idToken: ', idToken);

				fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/processMsg`, {
					method: 'POST',
					headers: { 'firebase-token': idToken },
					body: JSON.stringify({
						'phoneNumber':phoneNumber,
						'msgType': 'updateUserInfo',
						'msgPayload': {
							...validFormData,
							phoneNumber
						}
					})
				})
					.then(res => {
						if (res.ok) {
							// show update confirmation alert 
							setLoading(false);
							setupdateStatusSweetAlertMessage({
								updateInfoTitle:t('responseScreenTitle'),
								updateInfoMessage:t('userUpdateResponseText')
							});
						}
					})
					.catch(e => {
						//alert('error submitting.');
						setLoading(false);
						setupdateStatusSweetAlertMessage({
							updateInfoTitle:t('defaultErrorMessageTitle'),
							updateInfoMessage:t('defaultErrorMessage')
						});
					})
					.finally(() => setLoading(false));
				// fetch('https://nbtcreateconsent.azurewebsites.net/api/createconsent', { headers: {'firebase-token': 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjhmNDMyMDRhMTc5MTVlOGJlN2NjZDdjYjI2NGRmNmVhMzgzYzQ5YWIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbmV3Ym9ybnRpbWUtY29uc2VudCIsImF1ZCI6Im5ld2Jvcm50aW1lLWNvbnNlbnQiLCJhdXRoX3RpbWUiOjE2MjU1NzE3OTgsInVzZXJfaWQiOiI1QlN0UXNxTWhCaEkzVUEzU1k5YjJYV3dHUFEyIiwic3ViIjoiNUJTdFFzcU1oQmhJM1VBM1NZOWIyWFd3R1BRMiIsImlhdCI6MTYyNTU3MTgzMywiZXhwIjoxNjI1NTc1NDMzLCJwaG9uZV9udW1iZXIiOiIrNDc2NjY2NjY2NiIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsicGhvbmUiOlsiKzQ3NjY2NjY2NjYiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwaG9uZSJ9fQ.hsRCvL6WNCIoweAkRw2hZrSc2JsgUWI3wrP_meuYGFJVfKUWd4J3f5TiSFdHFN0ZyOSK1rr48edh5Wdoc5g0x8YjF60rntRkPJjGUnh6tSWLvmDlhiDHLQS-AIoTuFJWSRFUvpMEwsDq-EfTCn2PQjR64H9IzUPkkMeO9xDynVCsDFS4vZPEbRJhhKjiASO55xX-IVn2vyUhye5Xiyv_Y3ieCsqImXvX-X5aAwSsaV59CqVcK0aIFsFAAYeMo4cRg9cm2dZ21q03pAQVv1LCFQgX-KWkbhcdhqoWUSFDkW_89iM7WWE0ugEruBH6AZuIrtxhKYe-qHrp7tEv95siDg'}} );
			}).catch(function (error) {
				// Handle error
				setLoading(false);
				setupdateStatusSweetAlertMessage({
					updateInfoTitle:t('defaultErrorMessageTitle'),
					updateInfoMessage:t('defaultErrorMessage')
				});
				if (process.env.NODE_ENV === 'development')
					console.error('getIdToken:', error);
			});
	};

	const createNewConsentAndUserProfile = () => {
		setLoading(true);
		firebase.auth().currentUser.getIdToken(/* forceRefresh */)
			.then(function (idToken) {
				validFormData['motherResearchConsentStatus'] = (history.location.state['consentCheckbox1Checked']) ? '1' : '0';
				validFormData['motherEduConsentStatus'] = (history.location.state['consentCheckbox2Checked']) ? '1' : '0';
				// Send token to your backend via HTTPS
				if (process.env.NODE_ENV === 'development')
					console.log('idToken: ', idToken);

				fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/processMsg`, {
					method: 'POST',
					headers: { 'firebase-token': idToken },
					body: JSON.stringify({
						'phoneNumber':phoneNumber,
						'msgType': 'createUser',
						'msgPayload': {
							...validFormData,
							phoneNumber
						}
					})
				})
					.then(res => {
						setLoading(false);
						if (res.ok) {
							history.push('/formResponse');
						}
					})
					.catch(e => {
						//alert('error submitting.');
						setLoading(false);
						setupdateStatusSweetAlertMessage({
							updateInfoTitle:t('defaultErrorMessageTitle'),
							updateInfoMessage:t('defaultErrorMessage')
						});
					})
					.finally(() => setLoading(false));
				// fetch('https://nbtcreateconsent.azurewebsites.net/api/createconsent', { headers: {'firebase-token': 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjhmNDMyMDRhMTc5MTVlOGJlN2NjZDdjYjI2NGRmNmVhMzgzYzQ5YWIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbmV3Ym9ybnRpbWUtY29uc2VudCIsImF1ZCI6Im5ld2Jvcm50aW1lLWNvbnNlbnQiLCJhdXRoX3RpbWUiOjE2MjU1NzE3OTgsInVzZXJfaWQiOiI1QlN0UXNxTWhCaEkzVUEzU1k5YjJYV3dHUFEyIiwic3ViIjoiNUJTdFFzcU1oQmhJM1VBM1NZOWIyWFd3R1BRMiIsImlhdCI6MTYyNTU3MTgzMywiZXhwIjoxNjI1NTc1NDMzLCJwaG9uZV9udW1iZXIiOiIrNDc2NjY2NjY2NiIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsicGhvbmUiOlsiKzQ3NjY2NjY2NjYiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwaG9uZSJ9fQ.hsRCvL6WNCIoweAkRw2hZrSc2JsgUWI3wrP_meuYGFJVfKUWd4J3f5TiSFdHFN0ZyOSK1rr48edh5Wdoc5g0x8YjF60rntRkPJjGUnh6tSWLvmDlhiDHLQS-AIoTuFJWSRFUvpMEwsDq-EfTCn2PQjR64H9IzUPkkMeO9xDynVCsDFS4vZPEbRJhhKjiASO55xX-IVn2vyUhye5Xiyv_Y3ieCsqImXvX-X5aAwSsaV59CqVcK0aIFsFAAYeMo4cRg9cm2dZ21q03pAQVv1LCFQgX-KWkbhcdhqoWUSFDkW_89iM7WWE0ugEruBH6AZuIrtxhKYe-qHrp7tEv95siDg'}} );
			}).catch(function (error) {
				// Handle error
				setLoading(false);
				setupdateStatusSweetAlertMessage({
					updateInfoTitle:t('defaultErrorMessageTitle'),
					updateInfoMessage:t('defaultErrorMessage')
				});
				if (process.env.NODE_ENV === 'development')
					console.error('getIdToken:', error);
			});
	};


	const handleSubmit = (event: any) => {
		event.preventDefault();
		event.stopPropagation();

		setIsSubmitting(true);

		const formData = new FormData(event.target),
			formDataObj = Object.fromEntries(formData.entries());
		
		let errors;

		const userDataFromErrors = validatePersonalInfoForm(formDataObj as any);
		if (history.location.state['isNewUser']) {
			const consentCheckboxFormData = {};
			consentCheckboxFormData['motherResearchConsentStatus'] = (history.location.state['consentCheckbox1Checked']) ? '1' : '0';
			consentCheckboxFormData['motherEduConsentStatus'] = (history.location.state['consentCheckbox2Checked']) ? '1' : '0';
			const consentCheckboxFormDataerrors = validateConsentForm(consentCheckboxFormData as any);
			errors = {
				...userDataFromErrors,
				...consentCheckboxFormDataerrors
			};
		}
		else {
			errors = { ...userDataFromErrors };
		}
		if (Object.keys(errors).length) {
			//TODO: why keep prev?
			setErrorState(prev => ({ ...prev, ...errors }));
		} else {
			setValidFormData(valueState);
		}
	};



	const handleChange = (a) => {
		const name = a.target.name;
		// const value = a.target.type === 'checkbox' ? (a.target.checked ? '1' : '0') : a.target.value;
		const value = a.target.type === 'radio' ? parseInt(a.target.value) : a.target.value;

		const newErrors = validatePersonalInfoForm({ ...valueState, [name]: value } as any);
		setErrorState(newErrors);

		setValueState(values => ({ ...values, [name]: value }));

		// setIsSubmitting(false);
	};


	const handleBlur = (a) => {
		const { name } = a.target;
		setTouchedState(touched => ({ ...touched, [name]: true }));

		const newErrors = validatePersonalInfoForm(valueState as any);
		setErrorState(newErrors);
	};



	const onClickPreviousUserDataForm = (event) => {
		event.preventDefault();
		event.stopPropagation();
		history.goBack();
	};


	const onClickNextUserDataForm = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setconfirmFormSubmit(true);
		setIsformEditable(false);
	};


	const onClickEditUserDataForm = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setconfirmFormSubmit(false);
		setIsformEditable(true);
	};
	const onShowUpdateInfoToUserSweetAlertClickConfirm = () => {
		setupdateStatusSweetAlertMessage(null);
		history.push('/');
	};

	return (
		<>
			{
				loading && <CenterLoader/>
			}
			
			<div className="w-100">
				<div className="d-flex h-100 w-100 flex-column justify-content-center align-items-center container pb-5">
					<Form noValidate className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-4 px-2"  onSubmit={handleSubmit}>
								
						<div className="w-100 bg-white m-1 mt-3 p-2 py-4 rounded">
							<p className="userDataFormHeadingFont text-left">{t('userDataFormHeading')}</p>
									
							<Row>
								<Form.Group as={Col} sm="12" controlId="validation01" className="my-3">
									<Form.Label className="col-12 col-md-6 userDataFormLabels">{t('userDataFormFirstNameLabel')}</Form.Label>
									<Form.Control
										type="text"
										name="firstName"
										className="col-12 col-md-6"
										onChange={handleChange}
										onBlur={handleBlur}
										readOnly={!isFormEditable}
										//have `|| ''` to stop error: A component is changing a controlled input to be uncontrolled. This is likely caused by the value changing from a defined to undefined
										value={valueState.firstName || ''}
										isValid={(touchedState.firstName || isSubmitting) && !errorState.firstName}
										isInvalid={(touchedState.firstName || isSubmitting) && !!errorState.firstName}
										data-test="firstName"
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} sm="12" controlId="validation02" className="my-3">
									<Form.Label className="col-12 col-md-6 userDataFormLabels">{t('userDataFormLastNameLabel')}</Form.Label>
									<Form.Control
										type="text"
										name="lastName"
										className="col-12 col-md-6"
										value={valueState.lastName || ''}
										onChange={handleChange}
										onBlur={handleBlur}
										readOnly={!isFormEditable}
										isValid={(touchedState.lastName || isSubmitting) && !errorState.lastName}
										isInvalid={(touchedState.lastName || isSubmitting) && !!errorState.lastName}
										data-test="lastName"
									/>
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} sm="12" controlId="validationEmail" className="my-3">
									<Form.Label className="col-12 col-md-6 userDataFormLabels">{t('userDataFormEmailLabel')}</Form.Label>
									<Form.Control
										className="col-12 col-md-6"
										type="email"
										placeholder="example@example.com"
										name="email"
										value={valueState.email || ''}
										onChange={handleChange}
										onBlur={handleBlur}
										readOnly={!isFormEditable}
										isValid={(touchedState.email || isSubmitting) && !errorState.email}
										isInvalid={(touchedState.email || isSubmitting) && !!errorState.email}
									/>
									{/* <Form.Control.Feedback type="invalid">
											{errors.email}
										</Form.Control.Feedback> */}
								</Form.Group>
							</Row>
							<Row>
								<Form.Group as={Col} sm="12" controlId="validationUserId" className="my-3">
									{/* TODO: add question mark for userIDUser ID */}
									<Form.Label className="col-12 col-md-6 userDataFormLabels">
										<span className="userDataFormLabels">{t('userDataFormNationalIdLabel')}</span>
									</Form.Label>
									<Form.Control
										className="col-12 col-md-6"
										type="number"
										name="nin"
										placeholder=""
										aria-describedby="inputGroupPrepend"
										onChange={handleChange}
										onBlur={handleBlur}
										readOnly={!isFormEditable}
										value={valueState.nin || ''}
										isValid={(touchedState.nin || isSubmitting) && !errorState.nin}
										isInvalid={(touchedState.nin || isSubmitting) && !!errorState.nin}
										data-test="nin"
									/>
								</Form.Group>
							</Row>
									
						</div>
						{!(confirmFormSubmit) &&
							<div className="d-flex justify-content-center align-items-center ">
								<div className="p-2" data-test='smsCodeDiv'>
									{/* <button id="resend-sms-button" type="button" className="btn btn-primary" onClick={onClickResendSms} disabled={!(smsResendTimer == 0)} data-test='getSMSButton'>Send kode på nytt {!(smsResendTimer == 0) && '( ' + smsResendTimer + ' ) s'}</button> */}
									<button type="button" className="btn btn-primary" onClick={onClickPreviousUserDataForm} data-test='userDataFormPreviousButton'>{t('backButtonText')}</button>
								
								</div>
								<div className="p-2" data-test='smsCodeDiv'>
									<button type="button" className="btn btn-primary" onClick={onClickNextUserDataForm} disabled={(validFormData)?true:false} data-test='userDataFormNextButton'>{t('nextButtonText')}</button>
								</div>
							</div>
						}
								
							
						{(confirmFormSubmit) &&
							<div className="d-flex flex-column justify-content-center align-items-center">
								<p className="userDataFormAlertLabelFont">{t('userDataFormConfirmSubmitWarningText')}</p>
								<div className="d-flex justify-content-center align-items-center ">
									<div className="p-2" data-test='smsCodeDiv'>
										{/* <button id="resend-sms-button" type="button" className="btn btn-primary" onClick={onClickResendSms} disabled={!(smsResendTimer == 0)} data-test='getSMSButton'>Send kode på nytt {!(smsResendTimer == 0) && '( ' + smsResendTimer + ' ) s'}</button> */}
										<button type="button" className="btn btn-primary" onClick={onClickEditUserDataForm} data-test='userDataFormEditButton'>{t('editButtonText')}</button>
								
									</div>
									<div className="p-2" data-test='smsCodeDiv'>
										<button type="submit" className="btn btn-primary" data-test='userDataFormSubmitButton'>{t('confirmButtonText')}</button>
									</div>
								</div>
							</div>
						}
					</Form>
				</div>
						
				
				{updateStatusSweetAlertMessage &&
				<SweetAlert
					info
					title={updateStatusSweetAlertMessage.updateInfoTitle}
					onConfirm={onShowUpdateInfoToUserSweetAlertClickConfirm}
					onCancel={onShowUpdateInfoToUserSweetAlertClickConfirm}
				>{updateStatusSweetAlertMessage.updateInfoMessage}
				</SweetAlert>
				}
			</div>
		</>
	);
};

export { UserDataForm };

