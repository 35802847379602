import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useFirebaseUserState } from '../firebase/firebaseHook';
import { ConsentInformation } from './textInformations/ConsentInformation';
import firebase from 'firebase';
import SweetAlert from 'react-bootstrap-sweetalert';
import { signOutCurrentUser } from '../firebase/firebaseUtils';
import { useTranslation } from 'react-i18next';
import { CenterLoader } from './CenterLoader';

const CreateConsentForm = (): JSX.Element => {
	
	const [consentCheckbox1Checked, setconsentCheckbox1Checked] = useState(false);
	const [consentCheckbox2Checked, setconsentCheckbox2Checked] = useState(false);
	const [createConsentErrorMessage, setcreateConsentErrorMessage] = useState(null);
	const [loading, setLoading] = useState(false);

	const history = useHistory();
	
	const userState = useFirebaseUserState();
	const { t } = useTranslation();

	const phoneNumber = userState.initialized && userState.user.phoneNumber;

	
	const consentToResearchLabel = t('consentToResearchCheckboxLabel');
	const consentToEducationLabel = t('consentToEducationCheckboxLabel');

	const handleConsentCheckboxChange = (a) => {
		const name = a.target.name;
		const value = a.target.type === 'checkbox' ? (a.target.checked ? true : false) : a.target.value;
		if (name === 'consentCheckbox1') {
			setconsentCheckbox1Checked(value);
			if (!value) {
				setconsentCheckbox2Checked(value);	
			}
		}
		if (name === 'consentCheckbox2') {
			setconsentCheckbox2Checked(value);
		}
	};

	const logoutUser = () => {
		setLoading(true);
		signOutCurrentUser()
			.then(() => {
				//like F5
				//handle user state in app.tsx
				// window.location.pathname = '/';
				setLoading(false);
				history.replace('/');
			})
			.catch(() => {
				//alert('Could not sign out current user.');
				setLoading(false);
				setcreateConsentErrorMessage({
					createConsentErrorSweetAlertTitle: t('defaultErrorMessageTitle'),
					createConsentErrorSweetAlertMessage : t('defaultErrorMessage')
				});
			});

		// setAuthenticated(false);
	};
	const onClickCancelConsent = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (history.location.state['isNewUser']) {
			logoutUser();
		}
		else {
			history.goBack();
		}
	};

	const onClickConsentNext = (event) => {
		event.preventDefault();
		event.stopPropagation();
		if (history.location.state['isNewUser']) {
			history.push('/userPersonalInfo', { 'isNewUser':true, 'consentCheckbox1Checked':consentCheckbox1Checked, 'consentCheckbox2Checked':consentCheckbox2Checked});
		}
		else {
			createConsent();
		}
	};

	const createConsent = () => {
		setLoading(true);
		firebase.auth().currentUser.getIdToken(/* forceRefresh */)
			.then(function (idToken) {
				// Send token to your backend via HTTPS
				if (process.env.NODE_ENV === 'development')
					console.log('idToken: ', idToken);

				fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URL}/api/processMsg`, {
					method: 'POST',
					headers: { 'firebase-token': idToken },
					body: JSON.stringify({
						'phoneNumber':phoneNumber,
						'msgType': 'createConsent',
						'msgPayload':{
							phoneNumber,
							'motherResearchConsentStatus':consentCheckbox1Checked,
							'motherEduConsentStatus': consentCheckbox2Checked,
						}
					})
				})
					.then(res => {
						setLoading(false);
						if (res.ok) {
							history.push('/formResponse');
						}
						else {
							setcreateConsentErrorMessage({
								createConsentErrorSweetAlertTitle: t('defaultErrorMessageTitle'),
								createConsentErrorSweetAlertMessage : t('defaultErrorMessage')
							});
						}
					})
					.catch(e => {
						setLoading(false);
						setcreateConsentErrorMessage({
							createConsentErrorSweetAlertTitle: t('defaultErrorMessageTitle'),
							createConsentErrorSweetAlertMessage : t('defaultErrorMessage')
						});
					});
				// fetch('https://nbtcreateconsent.azurewebsites.net/api/createconsent', { headers: {'firebase-token': 'eyJhbGciOiJSUzI1NiIsImtpZCI6IjhmNDMyMDRhMTc5MTVlOGJlN2NjZDdjYjI2NGRmNmVhMzgzYzQ5YWIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbmV3Ym9ybnRpbWUtY29uc2VudCIsImF1ZCI6Im5ld2Jvcm50aW1lLWNvbnNlbnQiLCJhdXRoX3RpbWUiOjE2MjU1NzE3OTgsInVzZXJfaWQiOiI1QlN0UXNxTWhCaEkzVUEzU1k5YjJYV3dHUFEyIiwic3ViIjoiNUJTdFFzcU1oQmhJM1VBM1NZOWIyWFd3R1BRMiIsImlhdCI6MTYyNTU3MTgzMywiZXhwIjoxNjI1NTc1NDMzLCJwaG9uZV9udW1iZXIiOiIrNDc2NjY2NjY2NiIsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsicGhvbmUiOlsiKzQ3NjY2NjY2NjYiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJwaG9uZSJ9fQ.hsRCvL6WNCIoweAkRw2hZrSc2JsgUWI3wrP_meuYGFJVfKUWd4J3f5TiSFdHFN0ZyOSK1rr48edh5Wdoc5g0x8YjF60rntRkPJjGUnh6tSWLvmDlhiDHLQS-AIoTuFJWSRFUvpMEwsDq-EfTCn2PQjR64H9IzUPkkMeO9xDynVCsDFS4vZPEbRJhhKjiASO55xX-IVn2vyUhye5Xiyv_Y3ieCsqImXvX-X5aAwSsaV59CqVcK0aIFsFAAYeMo4cRg9cm2dZ21q03pAQVv1LCFQgX-KWkbhcdhqoWUSFDkW_89iM7WWE0ugEruBH6AZuIrtxhKYe-qHrp7tEv95siDg'}} );
			}).catch(function (error) {
				setLoading(false);
				setcreateConsentErrorMessage({
					createConsentErrorSweetAlertTitle: t('defaultErrorMessageTitle'),
					createConsentErrorSweetAlertMessage : t('defaultErrorMessage')
				});
				// Handle error
				if (process.env.NODE_ENV === 'development')
					console.error('getIdToken:', error);
			});
	};

	

	return (
		<>
			{
				loading && <CenterLoader/>
			}
			<div className="d-flex flex-column mt-3">
			
				<div className="d-flex justify-content-center container">
					<div className="d-flex flex-column align-items-center col-12 col-sm-12 col-md-11 col-lg-11 col-xl-8 pb-5">
						<ConsentInformation />
						<div className="d-flex justify-content-center align-items-center">
							<Form  className="pb-3 mx-auto px-3 mt-4">
								<div className="mb-3">
									<Form.Check className="my-2 consentCheckboxLabelFont" id ="consentToResearch" name="consentCheckbox1" onChange={handleConsentCheckboxChange} checked={consentCheckbox1Checked} type="checkbox" label={consentToResearchLabel} data-test="motherResearchConsentStatus"/>
									<Form.Check className="my-2 consentCheckboxLabelFont" id ="consentToEducation" name="consentCheckbox2" onChange={handleConsentCheckboxChange} checked={consentCheckbox2Checked} disabled={!consentCheckbox1Checked} type="checkbox" label={consentToEducationLabel}/>
								</div>
							</Form>
						</div>
						
						<div className="d-flex justify-content-center align-items-center ">
							<div className="p-2">
								<button type="button" className="btn btn-primary" onClick={onClickCancelConsent} data-test='consentCancelButton'>{t('cancelButtonText')}</button>
							</div>
							<div className="p-2">
								<button type="button" className="btn btn-primary" onClick={onClickConsentNext} disabled={!consentCheckbox1Checked} data-test='consentNextButton'>{t('nextButtonText')}</button>
							</div>
						</div>
						
					</div>


					{createConsentErrorMessage &&
						<SweetAlert
							danger
							title={createConsentErrorMessage.createConsentErrorSweetAlertTitle}
							onConfirm={()=>setcreateConsentErrorMessage(null)}
						>{createConsentErrorMessage.createConsentErrorSweetAlertMessage}
						</SweetAlert>
					}
			
				</div>
			</div>
		</>
	);
};

export { CreateConsentForm };

